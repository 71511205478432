import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import { FooterAlternate1 } from "../../../components/footer"
import SEO from "../../../components/seo"

const IndexPage = () => (
    <Layout>
      <SEO title="The power of personal branding" />

      <nav className="share-bar">
    <ul>
        <li className="linkedin">
            <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/&amp;title=The%20power%20of%20personal%20branding&amp;summary=The%20power%20of%20personal%20branding&amp;source=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <span className="icon">
                    <svg width="23" height="23">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-linkedin-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="twitter">
            <a href="https://twitter.com/intent/tweet/?text=The%20power%20of%20personal%20branding&amp;url=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <span className="icon">
                    <svg width="23" height="23">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-twitter-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="googleplus">
            <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Google+</span>
                <span className="icon">
                    <svg width="26" height="26">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-googleplus-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="facebook">
            <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <span className="icon">
                    <svg width="22" height="22">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-facebook-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="email">
            <Link to="knowledge-centre/insights/mailto:?subject=The%20power%20of%20personal%20branding&amp;body=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Email</span>
                <span className="icon">
                    <svg width="20" height="20">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-email-badge"></use>
                    </svg>
                </span>
            </Link>
        </li>
    </ul>
</nav>

<article className="article">

    

<header className="header header--article article__header header--video" id="page-header">
    <div className="header__image" data-video-uri="https://www.youtube.com/embed/4PCNeBdeZic?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" data-video-target="#page-header .embed-responsive" data-video-parent="#page-header">
        <div className="header__bgimage" style={{backgroundImage: "url(../../../media/images/Mentor-Large-John-Smibert.2e16d0ba.fill-1162x655.jpg)"}}></div>
        <div className="header__video">
            <div className="embed-responsive embed-responsive-16by9"></div>
        </div>
    </div>
    <div className="header__content">
        <div className="container">
            <div className="row">
                <div className="header__inner col-md-7 col-lg-offset-1">
                    <Link to="knowledge-centre/insights/" className="header__back back-link">
                        <span className="sr-only">Back to all articles</span>
                        <i className="icon"></i>
                    </Link>
                    <div className="header__title">
                        <h3>B2B Sales</h3>
                        <h1>The power of personal branding</h1>
                    </div>
                    <ul className="post-meta-list">
                        <li>by John Smibert</li>
                        <li>15 November 2016</li>
                        <li>2:22</li>
                    </ul>
                    <div className="header__icon play-icon">
                        <i className="icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>


<div className="container" id="article-content">
    <div className="row">
        <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7 wysiwyg">
            
<h3>What’s the power of personal branding for you?</h3>
<div className="rich-text"><p>Most sales people don’t realize is that they have now become commodities – most buyers think all sales people are the same, and that makes you replaceable. However, by creating a unique personal brand, you can differentiate yourself from the crowd, positioning yourself as someone with unique value to offer your buyers and your currnet and future employers. </p><p>The future is for those sales professionals who can differentiate themselves with a personal brand that sets them apart from all of the other sales people out there, and who are able to convey a unique promise of value. The future is for digitally savvy sales people who use all the channels they can to connect with their target customers, share unique insights and establish their position as a key person of influence in their industry. </p></div>


  <button type="button" className="btn btn-default" data-toggle="collapse" data-target="#transcript" aria-expanded="false" aria-controls="transcript">View full transcript</button>
  <div className="collapse" id="transcript">
  <h3>Transcript</h3>
  <div className="block-paragraph"><div className="rich-text"><p><b>What’s the Power of Personal Branding for you?</b></p><p>That will depend on what you’re trying to achieve.</p><p>For me it has been to make prospecting easier – to generate warm leads.</p><p>I thought you might like to know how I’ve done this?</p><p>I’ve engaged with thousands of potential clients via my personal branding activity and built the beginnings of a trusted relationship with many of these.</p><p>As an example – I have never met or spoken to a large proportion of the thousands I am connected with on LinkedIn. Most are in my target market because I’ve been very careful to connect with the right people.</p><p>A large proportion of my LinkedIn connections feel they know me – despite not having met me.  They have some understanding of the value I can create for them.I regularly share thought provoking content – content that is relevant to their job or business.  In some cases they have read my comments in a LinkedIn group – particularly in the one that I manage – the Strategic Selling group.</p><p>I try to give – without expecting anything in return. I never sell on social media.</p><p>Because I have connected and shared high quality content of specific and relevant value – many have developed a level of trust in my ‘promise of value’. This is validated in the reaction I get – when I call a connected person for the first time  – to set up a meeting – and generally after identifying a trigger event.</p><p>Typically I get a response something like:  “<em>Great to hear from you John – yes let’s meet</em>” – and that’s often before I have explained why I want a meeting.</p><p>That’s the power of Personal Branding for me.</p><p>What is it for you?</p></div></div>
  </div>


        </div>
            
        <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
            <div className="article__sidebar-wrap" id="article-sidebar">
                <div className="card card--profile">
                        
<div className="card__header">
    <div className="card__image">
        <img alt="Mentor Thumb John Smibert" className="img-responsive" height="165" src="../../../media/images/Mentor-Thumb-John-Smibert.2e16d0ba.fill-165x165.jpg" width="165"/>
    </div>
    <div className="card__title">
        <h4>John Smibert</h4>
        <p>Founder Sales Masterminds Australasia (SMA)</p>
        <Link to="solutions/find-a-mentor/john-smibert" className="btn btn-link hidden-md hidden-lg">View full profile</Link>
    </div>
</div>
<div className="rich-text"><p>Internationally recognized B2B sales specialist and thought
leader, John is a highly experience executive. John helps organisations who recognize the need to transform their sales capability, to respond to the tsunami of change that is starting to wash over us all.</p></div>
<Link to="solutions/find-a-mentor/john-smibert" className="btn btn-link hidden-xs hidden-sm">View full profile</Link>

                </div>
                <nav className="social-nav">
    <h4>Share</h4>
    <ul>
        <li className="linkedin">
            <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/&amp;title=The%20power%20of%20personal%20branding&amp;summary=The%20power%20of%20personal%20branding&amp;source=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <span className="icon icon-linkedin"></span>
                
            </a>
        </li>
        <li className="twitter">
            <a href="https://twitter.com/intent/tweet/?text=The%20power%20of%20personal%20branding&amp;url=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <span className="icon icon-twitter"></span>
                
            </a>
        </li>
        <li className="googleplus">
            <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Google+</span>
                <span className="icon icon-google-plus"></span>
                
            </a>
        </li>
        <li className="facebook">
            <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <span className="icon icon-facebook-f"></span>
                
            </a>
        </li>
        <li className="email">
            <Link to="knowledge-centre/insights/mailto:?subject=The%20power%20of%20personal%20branding&amp;body=https%3A//salestribe.com/knowledge-centre/insights/power-personal-branding/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Email</span>
                <span className="icon icon-envelope"></span>
                
            </Link>
        </li>
    </ul>
</nav>

            </div>
        </aside>
            
    </div>
</div>
</article>

<div className="block block--grey related-items">
    <div className="related-items__wrap">
        <div className="container">
            <div className="row" data-tablet-slick>
                
        <article className="related-items__item col-sm-6 col-md-4">
            <Link to="knowledge-centre/insights/sales-training-complete-waste-money-and-time-part-2" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-connect-coffee-chat.jpg" className="card__image" height="404" src="../../../media/images/article-connect-coffee-chat_xr8wJT.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales Training’ is a waste of money and time (Part 2)</strong></h3>
                    </header>
                    <p>We must stop teaching sales people how to ‘push’ buyers, and instead start training and coaching them on how to become highly specialised teachers.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="related-items__item col-sm-6 col-md-4">
            <Link to="knowledge-centre/insights/sales-training-waste-time-and-money-part-1" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-connect-coffee-chat.jpg" className="card__image" height="404" src="../../../media/images/article-connect-coffee-chat_xr8wJT.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>‘Sales Training’ is a waste of time and money (Part 1)</strong></h3>
                    </header>
                    <p>When you teach sales people how to ‘push’ buyers through a sales playbook you are teaching them how to destroy your business reputation.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="related-items__item col-sm-6 col-md-4">
            <Link to="knowledge-centre/insights/b2b-sales-has-changed-about-180-degrees" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">04:28</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>B2B Sales has changed about 180 Degrees!</strong></h3>
                    </header>
                    <p>B2B sales has now changed so dramatically that it&#39;s a &quot;profession&quot; that is barely recognisable to the role that both Tony Hughes and Graham Hawkins began about 30 years ago - respectively.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>

            </div>
            <div className="load-more">
                <Link to="knowledge-centre/insights/" className="btn btn-link">Browse more insights</Link>
            </div>
        </div>
    </div>
</div>
      
      <FooterAlternate1 />

    </Layout>
)

export default IndexPage 